import { useCallback, useMemo, useReducer } from "react";
export function useStepper(steps, initial) {
    void 0 === initial && (initial = steps[0]);
    let [step, dispatch] = useReducer((step, op)=>{
        var _steps_at;
        return "go" === op.type ? null != (_steps_at = steps.at(op.index)) ? _steps_at : steps[0] : "next" === op.type ? steps.indexOf(step) < steps.length - 1 ? steps[steps.indexOf(step) + 1] : step : "prev" === op.type && steps.indexOf(step) > 0 ? steps[steps.indexOf(step) - 1] : step;
    }, initial), next = useCallback(()=>void dispatch({
            type: "next"
        }), []), prev = useCallback(()=>void dispatch({
            type: "prev"
        }), []), go = useCallback((index)=>void dispatch({
            type: "go",
            index
        }), []);
    return useMemo(()=>({
            hasNext: steps.indexOf(step) < steps.length - 1,
            hasPrev: steps.indexOf(step) > 0,
            next,
            prev,
            step,
            go
        }), [
        step
    ]);
}
